<template>
    <v-dialog
        v-if="isVisible"
        v-model="isVisible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="isPending" >

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{$t("Загрузка...")}}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>

                        <v-form ref="form" lazy-validation>

                            <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Текст_ответа")}}
                                        <span>({{$t("каз._яз.")}})</span>
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea 
                                        v-model="answerTextKz"
                                        rows="3"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="answerTextRu ? requiredRule : []"
                                    />
                                </v-col>

                            </v-row>

                            <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Текст_ответа")}}
                                        <span>({{$t("рус._яз.")}})</span>
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea 
                                        v-model="answerTextRu"
                                        rows="3"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="answerTextKz ? requiredRule : []"
                                    />
                                </v-col>

                            </v-row>

                            <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">
                                        {{ $t("Приложение") }}
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group">
                                        <div @click="$refs.fileInputRef.click()">
                                            <input
                                                type="file"
                                                ref="fileInputRef"
                                                @change="onFileChanged"
                                                style="display: none"
                                            />
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        <div class="cloud-file-list-box">
                                            <div class="cloud-items-container">
                                                <div v-if="selectedItem === null" class="cfl-placeholder" >{{$t('Нет_файлов')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>

                            </v-row>

                            <v-tabs 
                                v-if="selectedItem"
                                class="cloud-file-list-box mb-2 csc-answer-file-tabs"  
                                v-model="selectedTab"
                                background-color="transparent"
                                mobile-breakpoint="0"
                                slider-size="3"
                                show-arrows
                                height="32"
                            >
                                <v-tabs-slider color="#E43958"></v-tabs-slider>

                                <v-tab 
                                    v-for="(item, key) in attachments"
                                    :key="key">
                                    <v-file-item                                             
                                        :name="item.name"
                                        :size="item.size"
                                        col-width="12"
                                        :hide-load="true"
                                        @on-delete-attachment="onDeleteAttachment(key)"
                                    />
                                </v-tab>

                            </v-tabs>

                            <v-tabs-items v-model="selectedTab" class="csc-answer-file-content">
                                <v-tab-item 
                                    v-for="(item, key) in attachments" 
                                    :key="key"
                                    >

                                    <v-row no-gutters v-if="selectedItem">

                                        <v-col cols="12" sm="12" md="3">
                                            <label class="f-label f-label-more-rows">
                                                {{$t("Описание_файла")}}
                                                <span>({{$t("каз._яз.")}})</span>
                                            </label>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="9">
                                            <v-text-field
                                                v-model="selectedDescriptionKz"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="selectedItem ? requiredRule : []"
                                            ></v-text-field>
                                        </v-col>

                                    </v-row>

                                    <v-row no-gutters v-if="selectedItem">

                                        <v-col cols="12" sm="12" md="3">
                                            <label class="f-label f-label-more-rows">
                                                {{$t("Описание_файла")}}
                                                <span>({{$t("рус._яз.")}})</span>
                                            </label>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="9">
                                            <v-text-field
                                                v-model="selectedDescriptionRu"
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                :rules="selectedItem ? requiredRule : []"
                                            ></v-text-field>
                                        </v-col>

                                    </v-row>

                                </v-tab-item>
                            </v-tabs-items> 
                            
                            <!-- <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">
                                        {{ $t("Приложение") }}
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group">
                                        <div @click="$refs.fileInputRef.click()">
                                            <input
                                                type="file"
                                                ref="fileInputRef"
                                                @change="onFileChanged"
                                                style="display: none"
                                            />
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        <div class="cloud-file-list-box">
                                            <div class="cloud-items-container">
                                                <div v-if="attachment === null" class="cfl-placeholder" >{{$t('Нет_файлов')}}</div>
                                                <v-row 
                                                    v-else
                                                    dense
                                                    >
                                                        <v-file-item
                                                            :name="attachment.name"
                                                            :size="attachment.size"
                                                            col-width="12"
                                                            :hide-load="true"
                                                            @on-delete-attachment="onDeleteAttachment"
                                                        />
                                                </v-row>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>

                            </v-row>

                            <v-row no-gutters v-if="attachment">

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Описание_файла")}}
                                        <span>({{$t("каз._яз.")}})</span>
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="descriptionKz"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="attachment ? requiredRule : []"
                                    ></v-text-field>
                                </v-col>

                            </v-row>

                            <v-row no-gutters v-if="attachment">

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Описание_файла")}}
                                        <span>({{$t("рус._яз.")}})</span>
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="descriptionRu"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="attachment ? requiredRule : []"
                                    ></v-text-field>
                                </v-col>

                            </v-row> -->

                        </v-form>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isValid"
                >
                    {{ $t("Отправить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/i18n'

export default {
    name: "CSCInvoiceDlg",
    MAX_FILES: 1,
    MAX_FILES_IS_PROACTIVE: 5,
    MAX_FILE_SIZE: 99614720,
    FORBIDDEN_EXTENSIONS: [ "ade", "adp", "gz", "tar", "bat", "chm", "cmd", "com", "cpl", "exe", "hta", "ins", "isp", "jse", "lib", "lnk", "mde", "msc", "msp", "mst", "pif", "scr", "sct", "shb", "sys", "vb", "vbe", "vbs", "vxd", "wsc", "wsf", "wsh", "apx", "dll", "url" ],
    data () {
        return {
            title: "Сформировать_счет_на_оплату",
            selectedItem: null,
            tabItem: null
        }
    },
    computed: {
        ...mapGetters('actionsource', { getDataSource: 'getDataSource'}),
        ...mapGetters('dialogs/cscInvoice', ['isVisible', 'isValid', 'isPending']),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
        }),
        selectedTab: {
            get: function() {
                return this.tabItem;
            },
            set: function (v) {
                this.tabItem = v;
                this.selectedItem = this.attachments[v] ?? null;
            }
        },

        selectedDescriptionKz: {
            get: function() {
                return this.selectedItem ? this.selectedItem.descriptionKz : '';
            },
            set: function(v) {
                this.selectedItem.descriptionKz = v;
                this.$store.commit('dialogs/cscInvoice/SET_ATTACHMENTS', this.attachments.map(a=>a));
            }
        },
        selectedDescriptionRu: {
            get: function() {
                return this.selectedItem ? this.selectedItem.descriptionRu : '';
            },
            set: function(v) {
                this.selectedItem.descriptionRu = v;
                this.$store.commit('dialogs/cscInvoice/SET_ATTACHMENTS', this.attachments.map(a=>a));
            }
        },
        answerTextKz: {
            get: function() {
                return this.$store.getters['dialogs/cscInvoice/answerTextKz'];
            },
            set: function(v) {
                this.$store.commit('dialogs/cscInvoice/SET_ANSWER_TEXT_KZ', v); 
            }
        },
        answerTextRu: {
            get: function() {
                return this.$store.getters['dialogs/cscInvoice/answerTextRu'];
            },
            set: function(v) {
                this.$store.commit('dialogs/cscInvoice/SET_ANSWER_TEXT_RU', v); 
            }
        },
        attachments: {
            get: function() {
                return this.$store.getters['dialogs/cscInvoice/attachments'];
            }
        },
        isProactive: {
            get: function() {
                return this.getDataSource?.Data?.Object?.Document?.Card?.IsProactive ?? false;
            }
        }       
    },
    methods: {
        ...mapGetters('actionsource', { getDataSource: 'getDataSource'}),
        ...mapActions('dialogs/cscInvoice', ['ok', 'cancel']),
        onFileChanged(event) {
            let file = Array.from(event.target.files)?.[0];
            if (file) 
            {

                if (!this.isProactive && !this.canMakeContract && this.attachments.length > this.$options.MAX_FILES_IS_PROACTIVE)
                {
                    this.$notify.alert(i18n.t("Превышен_лимит_количества_файлов"));
                    return;
                }

                if (file.size > this.$options.MAX_FILE_SIZE) {
                    this.$notify.alert(i18n.t("Превышен_лимит_размера_вложения"));
                    return;
                }

                let fileExtention = file.name.match(new RegExp('[^.]+$'))[0].toLowerCase();
                if (this.$options.FORBIDDEN_EXTENSIONS.includes(fileExtention)) {
                    this.$notify.alert(i18n.t("Недопустимое_расширение_файла"));
                    return;
                }

                if (this.attachments.map(i => i.name).includes(file.name))
                {
                    this.$notify.alert(i18n.t("Файл_с_таким_именем_уже_выбран"));
                    return;
                }
                
                if (this.isProactive || this.canMakeContract)
                    this.$store.commit('dialogs/cscInvoice/SET_ATTACHMENTS', []);

                this.$store.commit('dialogs/cscInvoice/PUSH_ATTACHMENT', file);
                this.selectedTab = this.attachments.length - 1;
            }

            event.target.value = '';
            this.validateFormMethod();
        },
        // onFileChanged(event) {
        //     let file = Array.from(event.target.files)?.[0];
        //     if (file) {

        //         if (file.size > this.$options.MAX_FILE_SIZE) {
        //             this.$notify.alert(i18n.t("Превышен_лимит_размера_вложения"));
        //             this.$store.commit('dialogs/cscInvoice/SET_ATTACHMENT', null);
        //             this.$refs.fileInputRef.reset();
        //             return;
        //         }

        //         let fileExtention = file.name.match(new RegExp('[^.]+$'))[0].toLowerCase();
        //         if (this.$options.FORBIDDEN_EXTENSIONS.includes(fileExtention)) {
        //             this.$notify.alert(i18n.t("Недопустимое_расширение_файла"));
        //             this.$store.commit('dialogs/cscInvoice/SET_ATTACHMENT', null);
        //             this.$refs.fileInputRef.reset();
        //             return;
        //         }

        //         this.$store.commit('dialogs/cscInvoice/SET_ATTACHMENT', file);
        //         this.selectedItem = file;
        //     }
        //     else
        //     {
        //         this.$store.commit('dialogs/cscInvoice/SET_ATTACHMENT', null);
        //         this.$store.commit('dialogs/cscInvoice/SET_DESCRIPTION_KZ', null);
        //         this.$store.commit('dialogs/cscInvoice/SET_DESCRIPTION_RU', null);
        //     }
        //     event.target.value = '';
        // },
        onDeleteAttachment(index){
            this.$store.commit('dialogs/cscInvoice/DEL_ATTACHMENT', index);
            this.selectedTab =  this.attachments.length > 0 ?  this.attachments.length - 1 : null;
        },
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    },
    updated: function () {
        this.validateFormMethod();
    }
}
</script>